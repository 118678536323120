@tailwind base;
@tailwind components;
@tailwind utilities;

/* Background Animation */
body {
  background: linear-gradient(-45deg, #ffcdb2, #ffb4a2);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  min-height: 100vh;
    display: flex;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

#root {
  flex-grow: 1;
}

.heading {
  font-family: 'Permanent Marker', cursive;
}

.footer {
  font-family: 'Victor Mono', monospace;
}